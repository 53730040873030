import { ChangeDetectionStrategy, Component, inject, signal, WritableSignal } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromAddresses from '../../../addresses/store';
import { SwPush } from '@angular/service-worker';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-notification-test-panel',
  templateUrl: './notification-test-panel.component.html',
  styleUrls: ['./notification-test-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationTestPanelComponent {
  private Store = inject(Store);
  private swPush = inject(SwPush);

  address$ = this.Store.selectSignal(fromAddresses.getAddress);

  subscribed$: WritableSignal<boolean> = signal(this.swPush.isEnabled);
  subscription$ = toSignal(this.swPush.subscription);

  async subscribe() {
    var result = await Notification.requestPermission();
    this.subscribed$.set(result === 'granted');
  }

  async iosSubscribe() {
    const w = <any>window;
    const canPush = w.webkit && w.webkit.messageHandlers && w.webkit.messageHandlers['push-permission-request'];

    if (canPush) w.webkit.messageHandlers['push-permission-request'].postMessage('push-permission-request');

    window.addEventListener('push-permission-request', (message: any) => {
      if (message && message.detail) {
        console.log(message);
        this.subscribed$.set(message.detail === 'granted');
      }
    });
  }

  async pushSubscribe() {
    console.log(this.subscription$());
    try {
      var result = await this.swPush.requestSubscription({
        serverPublicKey: 'BKdQ5C1Yn2VrGgpyfvmCO0uO0D5oQaMFKrmFkkAZjP0I0cr5nkZnfm5a53S5auE8Ct9u72_pePAqbwMqxyZDOhI',
      });
      console.log(result);
      this.subscribed$.set(this.swPush.isEnabled);
    } catch (error) {
      console.log(error);
    }
  }

  async send() {
    var n = new Notification('Uw PMD-container mag aan de weg!', {
      body: 'Morgenochtend komen we langs om uw PMD-container te legen',
      vibrate: [10, 20, 10],
      icon: '/mijn-rova/assets/gfx/favicon/android-chrome-192x192.png',
      badge: '/mijn-rova/assets/gfx/favicon/maskable_icon_x192.png',
    });
    console.log(JSON.stringify(n));
  }
}
