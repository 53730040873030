import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotificationTestPanelComponent } from './containers/notification-test-panel/notification-test-panel.component';
import { CapacitorFirebaseWebComponent } from './containers/capacitor-firebase-web/capacitor-firebase-web.component';

const routes: Routes = [
  {
    path: 'test-panel',
    component: NotificationTestPanelComponent,
  },
  {
    path: 'capacitor',
    component: CapacitorFirebaseWebComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotificationsRoutingModule {}
