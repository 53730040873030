import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationsRoutingModule } from './notifications-routing.module';
import { NotificationTestPanelComponent } from './containers/notification-test-panel/notification-test-panel.component';
import { CoreModule } from '../core/core.module';
import { AddressesModule } from '../addresses/addresses.module';
import { CapacitorFirebaseWebComponent } from './containers/capacitor-firebase-web/capacitor-firebase-web.component';
import { StoreModule } from '@ngrx/store';

import * as fromStore from './store';
import { EffectsModule } from '@ngrx/effects';
import { NotificationSettingsFormComponent } from './components/notification-settings-form/notification-settings-form.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TimePickerToggleComponent } from '../form/components/time-picker-toggle/time-picker-toggle.component';
import { LoadingSpinnerComponent } from '../standalone/loading-spinner/loading-spinner.component';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { IconModule } from '@teamfoster/sdk/icon';
import { AlertComponent } from '../standalone/alert/alert.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { WasteCalendarModule } from '../waste-calendar/waste-calendar.module';
@NgModule({
  declarations: [NotificationTestPanelComponent, CapacitorFirebaseWebComponent, NotificationSettingsFormComponent],
  exports: [NotificationSettingsFormComponent],
  imports: [
    CommonModule,
    CoreModule,
    NotificationsRoutingModule,
    AddressesModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatInputModule,
    TimePickerToggleComponent,
    LoadingSpinnerComponent,
    DictionaryNgrxModule,
    IconModule,
    AlertComponent,
    WasteCalendarModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    EffectsModule.forFeature(fromStore.effects),
  ],
})
export class NotificationsModule {}
