import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FirebaseMessaging, GetTokenOptions } from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-capacitor-firebase-web',
  templateUrl: './capacitor-firebase-web.component.html',
  styleUrls: ['./capacitor-firebase-web.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CapacitorFirebaseWebComponent {
  public token = '';

  constructor() {}

  public async requestPermissions(): Promise<void> {
    await FirebaseMessaging.requestPermissions();
  }

  public async getToken(): Promise<void> {
    const options: GetTokenOptions = {
      vapidKey: environment.firebase.vapidKey,
    };
    if (Capacitor.getPlatform() === 'web') {
      const registration = await navigator.serviceWorker.ready;
      options.serviceWorkerRegistration = registration;
    }
    const { token } = await FirebaseMessaging.getToken(options);
    this.token = token;
  }
}
