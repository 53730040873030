import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormModule } from '../../form.module';
import { IconModule } from '@teamfoster/sdk/icon';
import { NotificationMoment } from 'src/app/notifications/models';
import { NotificationFormRow } from 'src/app/notifications/components/notification-settings-form/notification-settings-form.component';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { TimeSelectDialogComponent } from '../../dialogs/time-select-dialog/time-select-dialog.component';

@Component({
  selector: 'app-time-picker-toggle',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormModule, IconModule, MatDialogModule],
  templateUrl: './time-picker-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePickerToggleComponent implements OnInit, OnDestroy {
  @Input() label?: string;
  @Input({ required: true }) group!: AbstractControl<any>;

  fb = inject(FormBuilder);
  dlg = inject(MatDialog);
  private _unsubscribe = new Subject<void>();

  @Output() valueChanges = new EventEmitter<any>();

  @ViewChild('input') input?: ElementRef<HTMLInputElement>;

  ngOnInit(): void {
    this.group.valueChanges.pipe(takeUntil(this._unsubscribe)).subscribe((a: any) => this.valueChanges.emit(a));
  }

  async openPicker() {
    var result = await firstValueFrom(this.dlg.open(TimeSelectDialogComponent, { data: { value: this.formControl?.value } }).afterClosed());
    if (result?.value) {
      this.formControl?.setValue(result.value);
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  get formControl() {
    return this.group?.get('receiveNotificationsOn');
  }
  get formGroup() {
    return this.group as FormGroup;
  }
}
