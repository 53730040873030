import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-time-select-dialog',
  standalone: true,
  imports: [MatDialogModule, MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule],
  templateUrl: './time-select-dialog.component.html',
  styleUrl: './time-select-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSelectDialogComponent {
  private fb = inject(FormBuilder);

  data = inject(MAT_DIALOG_DATA);

  private dlg = inject(MatDialogRef);

  get hoursValue() {
    return this.data.value?.split(':')[0] || '';
  }

  get minutesValue() {
    return this.data.value?.split(':')[1] || '';
  }

  timeForm = this.fb.group({
    hours: [`${this.hoursValue}`, Validators.required],
    minutes: [`${this.minutesValue}`, Validators.required],
  });

  //eslint-disable max-len
  //prettier-ignore
  hours = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];
  minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

  save() {
    if (this.timeForm.invalid) {
      return;
    }
    const value = `${this.timeForm.value.hours}:${this.timeForm.value.minutes}`;
    this.dlg.close({ value });
  }
}
