<article class="dashboard">
  <app-header class="dashboard__header" [address]="address$() || undefined" [subtitle]="'Notifications'" [imageAnchor]="'topright'">
    <!-- Check of inzamelgegevens dashboard getoond mag worden, voor nu mag dat alleen in zwolle -->
  </app-header>

  <main class="dashboard__main section-inset-y bg-space-gray-200">
    <div class="container dashboard__main__container">
      <section class="section-outset-t mt-6">
        <h2 class="h6 font-secondary text-secondary mb-3 ms-md-4">Notification tools</h2>

        <button class="button button--primary" (click)="subscribe()">Subscribe</button>
        <button class="button button--primary" (click)="pushSubscribe()">Push subscribe</button>
        <button class="button button--primary" (click)="send()">Send test notification</button>

        <pre>
          {{ subscribed$() | json }}
        </pre>
      </section>
    </div>
  </main>
</article>
