<section class="pt-topbar">
  <div class="bg-space-gray-200">
    <div class="container section-inset-y">
      <h2 class="text-secondary mb-3">capacitor-firebase-web</h2>

      <p>1. Make sure that the first thing you do is to request the permission using the Request Permissions button.</p>
      <button class="button button--primary me-4" (click)="requestPermissions()">Request Permissions</button>

      <p>
        Next, request the FCM token and copy the FCM token to your clipboard so that you can next send a push notification to this device
        via the Firebase Console.
      </p>
      <button class="button button--secondary" (click)="getToken()">Get Token</button>

      <input type="text" readonly [value]="token" />

      <p>
        en dan verder met
        <a href="https://capawesome.io/blog/the-push-notifications-guide-for-capacitor/#send-push-notifications-with-firebase">
          Send Push Notifications with Firebase
        </a>
      </p>
    </div>
  </div>
</section>
