<label class="field-notification-time" for="" [formGroup]="formGroup">
  <span class="field-notification-time__label" *ngIf="label">{{ label }}</span>

  <div class="field-notification-time__container">
    <button
      class="button button-link button--secondary h-auto"
      type="button"
      (click)="formControl?.setValue('')"
      *ngIf="formControl?.value"
    >
      <fstr-icon anchor="icon-trash"></fstr-icon>
      <span class="d-none d-md-inline-block">Verwijder</span>
    </button>
    <div class="field-notification-time__field" (click)="openPicker()">
      <div class="field-notification-time__field__placeholder" *ngIf="!formControl?.value">Kies tijd</div>
      <label class="field-notification-time__field__label">
        <input
          class="field-notification-time__field__input no-pointer-events"
          #input
          matInput
          type="time"
          placeholder="Placeholder"
          formControlName="receiveNotificationsOn"
        />
      </label>
    </div>
  </div>
</label>
