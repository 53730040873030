import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Signal,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { GripLocation, GripSubscription, ManualSubscription, NotificationMoment, Subscription } from '../../models';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { getAddress } from 'src/app/addresses/store';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale/nl';
import { Dialog } from '@capacitor/dialog';

import * as fromStore from '../../store';
import * as fromWasteCalendar from '../../../waste-calendar/store';
import { Platform } from '@angular/cdk/platform';
import { Capacitor } from '@capacitor/core';
import { HideCompletedProfile } from 'src/app/settings/store';

export interface NotificationFormRow {
  receiveNotificationsOn: FormControl<string>; // time
  id: FormControl<number>; // per moment
  notificationMoment: FormControl<NotificationMoment>;
}
@Component({
  selector: 'app-notification-settings-form',
  templateUrl: './notification-settings-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationSettingsFormComponent {
  fb = inject(FormBuilder);
  private store = inject(Store);
  private cd = inject(ChangeDetectorRef);
  private platform = inject(Platform);

  private manualToggle = false;

  FCMToken$ = this.store.selectSignal(fromStore.getNotificationToken);
  permissionsGranted$ = this.store.selectSignal(fromStore.getNotificationPermissionsGranted);
  address$ = this.store.selectSignal(getAddress);
  permissionsLoading$ = this.store.selectSignal(fromStore.getNotificationPermissionsPending);
  permissionsError$ = this.store.selectSignal(fromStore.getNotificationPermissionsError);
  subscriptions$ = this.store.selectSignal(fromStore.getAllSubscriptions);
  subscriptionsLoading$ = this.store.selectSignal(fromStore.getSubscriptionsLoading);
  subscriptionsLoaded$ = this.store.selectSignal(fromStore.getSubscriptionsLoaded);
  hasWasteCalendarItems$ = this.store.selectSignal(fromWasteCalendar.hasWasteCalendarItems);

  get NotificationsUnsupported() {
    return Capacitor.getPlatform() === 'web' && this.platform.IOS;
  }

  // Grip
  GripSubscription$: Signal<GripSubscription | undefined> = this.store.selectSignal(fromStore.getFirstGripSubscription);
  GripLocations$ = this.store.selectSignal(fromStore.getAllGripLocations);

  // Manual Notifications
  ManualSubscription$: Signal<ManualSubscription | undefined> = this.store.selectSignal(fromStore.getFirstManualSubscription);

  prettyErrorCode$ = computed(() => {
    const error = this.permissionsError$();

    return 'error-' + error['code'].split('/').join('-');
  });

  test = effect(() => {
    if (this.subscriptionsLoaded$() === true) {
      this.hasChanges = false;
      this.cd.detectChanges();
    }
    //asapScheduler.schedule(() =>
  });

  autoToggleManualSubscriptions = effect(
    () => {
      const token = this.FCMToken$();
      if (this.manualToggle === true && token) {
        this.toggleManualNotifications({ checked: true } as MatSlideToggleChange);
        this.manualToggle = false;
        return;
      }
    },
    { allowSignalWrites: true }
  );

  subscriptions = this.fb.group({
    moments: this.fb.array([this.getFormRow(1), this.getFormRow(0)]),
  });

  hasChanges = false;
  // todo show icon-check when selectedGripLocation is saved.
  // hasSelectedGripLocation: boolean = false;
  // saveSelectedGriplocation() {
  //   this.hasSelectedGripLocation = !this.hasSelectedGripLocation;
  // }

  get rows() {
    return this.subscriptions.get('moments') as FormArray;
  }

  async toggleNotifications(e: MatSlideToggleChange) {
    if (e.checked) {
      this.store.dispatch(HideCompletedProfile());
      this.store.dispatch(fromStore.RequestPermissions());
      this.manualToggle = true;
      return true;
    }
    if (!e.checked) {
      const { value } = await Dialog.confirm({
        title: 'Weet u het zeker?',
        message: `Weet u zeker dat u notificaties uit wil schakelen?`,
        okButtonTitle: 'OK',
        cancelButtonTitle: 'Annuleren',
      });
      if (value) {
        this.store.dispatch(fromStore.DeleteSubscriptions({ token: this.FCMToken$() }));
        this.store.dispatch(fromStore.DisableNotifications());
        return true;
      }
    }

    e.source.checked = true;
    return false;
  }

  async toggleGripNotifications(e: MatSlideToggleChange) {
    if (e.checked) {
      // TODO: Create subscription
      return true;
    } else if (this.GripSubscription$() === undefined) {
      return true;
    } else if (!e.checked) {
      const { value } = await Dialog.confirm({
        title: 'Weet u het zeker?',
        message: 'Weet u zeker dat u notificaties voor de GRIP-wagen uit wil schakelen?',
        okButtonTitle: 'OK',
        cancelButtonTitle: 'Annuleren',
      });
      if (value) {
        this.store.dispatch(fromStore.DeleteGripSubscriptions({ token: this.FCMToken$() }));
        return true;
      }
    }

    e.source.checked = true;
    return false;
  }

  async toggleManualNotifications(e: MatSlideToggleChange) {
    if (e.checked) {
      if (!this.address$()?.koad) {
        return;
      }
      this.store.dispatch(fromStore.SaveManualSubscription({ koad: this.address$()?.koad || '', token: this.FCMToken$() }));
      return true;
    } else if (this.ManualSubscription$() === undefined) {
      return true;
    } else if (!e.checked) {
      const { value } = await Dialog.confirm({
        title: 'Weet u het zeker?',
        message: 'Weet u zeker dat u algemene notificaties uit wil schakelen?',
        okButtonTitle: 'OK',
        cancelButtonTitle: 'Annuleren',
      });
      if (value) {
        this.store.dispatch(fromStore.DeleteManualSubscriptions({ token: this.FCMToken$() }));
        return true;
      }
    }

    e.source.checked = true;
    return false;
  }

  getFormRow(notificationMoment: NotificationMoment) {
    const sub = this.subscriptions$().find(a => a.notificationMoment === notificationMoment);

    var time = '';

    if (sub) {
      const date = new Date(sub?.receiveNotificationsOn);
      time = format(date, 'HH:mm', { locale: nl }); //, { locale: nl }
    }

    return this.fb.group<NotificationFormRow>({
      receiveNotificationsOn: this.fb.control(time, { nonNullable: true }),
      id: this.fb.control(sub?.id || 0, { nonNullable: true }),
      notificationMoment: this.fb.control(notificationMoment, { nonNullable: true }),
    });
  }

  dayLabel(moment: NotificationMoment) {
    return moment === NotificationMoment.SameDay ? 'notificatie-moment-label-zelfde-dag' : 'notificatie-moment-label-dag-ervoor';
  }

  onSubmit() {
    const subscriptions = this.subscriptions.value.moments
      ?.map(
        a =>
          ({
            id: a.id,
            fCMToken: this.FCMToken$(),
            receiveNotificationsOn: a.receiveNotificationsOn,
            notificationMoment: a.notificationMoment,
          }) as Subscription
      )
      .filter(a => a.receiveNotificationsOn)
      .map(a => ({ ...a, receiveNotificationsOn: new Date(`${new Date().toDateString()} ${a.receiveNotificationsOn}`).toUTCString() }));

    if (!this.address$()?.koad) {
      return;
    }

    this.store.dispatch(
      fromStore.SaveSubscriptions({ koad: this.address$()!.koad!, subscriptions: subscriptions || [], token: this.FCMToken$() })
    );
  }

  setGripLocation(locationId: string) {
    const subscription: GripSubscription = {
      fCMToken: this.FCMToken$(),
      gripLocationId: +locationId,
      id: this.GripSubscription$()?.id || 0,
    };
    this.store.dispatch(fromStore.SaveGripSubscription({ subscription, token: this.FCMToken$() }));
  }
}
