<article class="notifications-form">
  <div class="setting-group__header mb-0">
    <label class="setting-group__header__label fw-bold text-primary fs-body-md" for="enableNotifications">
      {{ 'app-instellingen-meldingen-toestaan' | fromDictionary }}
      <!-- <p class="fs-body-xxxs pe-5 pt-1 mb-0 text-dark fw-normal" *ngIf="permissionsGranted$() && !permissionsLoading$()">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi cupiditate inventore, temporibus similique dicta est?
      </p> -->
    </label>

    <mat-slide-toggle
      [disabled]="NotificationsUnsupported"
      id="enableNotifications"
      [checked]="permissionsGranted$()"
      (change)="toggleNotifications($event)"
    ></mat-slide-toggle>
  </div>
  <!--<pre>{{ FCMToken$() }}</pre>-->
  @if (NotificationsUnsupported) {
    <app-alert
      class="mt-3 d-block"
      [status]="'warning'"
      [message]="'notificatie-instellingen-niet-ondersteund' | fromDictionary"
    ></app-alert>
  }
  <app-loading-spinner
    class="text-secondary"
    *ngIf="permissionsLoading$()"
    [text]="'notificatie-instellingen-laden' | fromDictionary"
  ></app-loading-spinner>

  @if (permissionsGranted$() && !permissionsLoading$()) {
    <form (ngSubmit)="onSubmit()" [formGroup]="subscriptions">
      <div class="setting-group__header mt-4 mb-3">
        <div>
          <label class="setting-group__header__label fw-bold text-primary fs-body-md d-block mb-1" for="enableManualNotifications">
            {{ 'app-instellingen-meldingen-manual-titel' | fromDictionary }}
            <!-- <a href="#" [matTooltip]="'app-instellingen-meldingen-manual-tooltip-tekst' | fromDictionary" target="_blank" class="">
              <fstr-icon class="icon-xs" anchor="icon-question-small"></fstr-icon>
            </a> -->
          </label>

          <p class="lh-base mb-0" *ngIf="'app-instellingen-meldingen-manual-titel-tekst' | fromDictionary: false">
            {{ 'app-instellingen-meldingen-manual-titel-tekst' | fromDictionary }}
          </p>
        </div>

        <mat-slide-toggle
          id="enableManualNotifications"
          [checked]="ManualSubscription$() !== undefined"
          (change)="toggleManualNotifications($event)"
          #manualEnabled
        ></mat-slide-toggle>
      </div>
      @if (hasWasteCalendarItems$()) {
        <label class="setting-group__header__label fw-bold text-primary fs-body-md">
          {{ 'app-instellingen-meldingen-inzamelmoment-titel' | fromDictionary }}
          <!-- <p class="fs-body-xxxs pe-5 pt-1 mb-0 text-dark fw-normal" *ngIf="permissionsGranted$() && !permissionsLoading$()">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi cupiditate inventore, temporibus similique dicta est?
      </p> -->
        </label>
        <p class="lh-base" [class.mb-0]="!permissionsGranted$()" *ngIf="'notificatie-settings-tekst' | fromDictionary: false">
          {{ 'notificatie-settings-tekst' | fromDictionary }}
        </p>
        <fieldset class="d-block mt-2" formArrayName="moments">
          <ng-container *ngFor="let control of rows.controls; index as i">
            <app-time-picker-toggle
              class="d-block mb-2"
              [label]="dayLabel(control.get('notificationMoment')?.value) | fromDictionary"
              [group]="control"
              (valueChanges)="hasChanges = true"
            ></app-time-picker-toggle>
          </ng-container>
        </fieldset>
      }
      @if (GripLocations$().length) {
        <div class="setting-group__header mt-4 mb-0">
          <label class="setting-group__header__label fw-bold text-primary fs-body-md" for="enableGripNotifications">
            {{ 'app-instellingen-meldingen-grip-titel' | fromDictionary }}
            <a
              [href]="'app-instellingen-meldingen-grip-info-link' | fromDictionary"
              [matTooltip]="'app-instellingen-meldingen-grip-tooltip-tekst' | fromDictionary"
              target="_blank"
              class=""
            >
              <fstr-icon class="icon-xs" anchor="icon-question-small"></fstr-icon>
            </a>
          </label>
          <mat-slide-toggle
            id="enableGripNotifications"
            [checked]="GripSubscription$() !== undefined"
            (change)="toggleGripNotifications($event)"
            #gripEnabled
          ></mat-slide-toggle>
        </div>
        <mat-form-field
          [subscriptSizing]="'dynamic'"
          appearance="fill"
          color="accent"
          class="w-100 me-3 flex-grow-1 mt-3"
          *ngIf="gripEnabled.checked"
        >
          <mat-label>Selecteer locatie</mat-label>
          <select
            matNativeControl
            [value]="GripSubscription$()?.id"
            (change)="hasChanges = true; setGripLocation(gripLocationSelect.value)"
            #gripLocationSelect
          >
            <option [selected]="!GripSubscription$()" [value]="null">- Selecteer een gripwagenlocatie -</option>
            <option *ngFor="let m of GripLocations$()" [selected]="m.id === GripSubscription$()?.gripLocationId" [value]="m.id">
              {{ m.title }}
            </option>
          </select>
          <!-- <fstr-icon *ngIf="hasSelectedGripLocation" anchor="icon-check"></fstr-icon> -->
        </mat-form-field>
      }
      @if (hasChanges) {
        <footer class="notifications-form__footer is--success text-center">
          <button class="button button--primary mx-auto" type="submit" [disabled]="subscriptionsLoading$() === true">
            <fstr-icon anchor="icon-loading" class="icon--rotating" *ngIf="subscriptionsLoading$() === true"></fstr-icon>
            <span>Wijzigingen opslaan</span>
          </button>
        </footer>
      }
    </form>
  }

  <app-alert class="mt-3 d-block" *ngIf="permissionsError$()" [status]="'danger'" [message]="'Er is iets misgegaan...'">
    <span class="d-block mt-1" [innerHTML]="prettyErrorCode$() | fromDictionary" *ngIf="prettyErrorCode$() | fromDictionary: false"></span>
    <!--<pre>{{ permissionsError$() | json }}</pre>-->
  </app-alert>
</article>
