<h2 mat-dialog-title class="mb-2">Selecteer een tijd</h2>
<mat-dialog-content>
  <form class="example-form" [formGroup]="timeForm">
    <div class="row">
      <div class="col-6">
        <mat-form-field [subscriptSizing]="'dynamic'">
          <mat-label>Uren</mat-label>
          <mat-select formControlName="hours">
            @for (hour of hours; track $index) {
              <mat-option [value]="hour">{{ hour }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field [subscriptSizing]="'dynamic'">
          <mat-label>Minuten</mat-label>
          <mat-select formControlName="minutes">
            @for (minute of minutes; track $index) {
              <mat-option [value]="minute">{{ minute }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
  <button class="button button--sm button-link me-3" mat-dialog-close>Annuleren</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button [disabled]="timeForm.invalid" class="button button--sm button--primary" (click)="save()">Instellen</button>
</mat-dialog-actions>
